import { createRouter, createWebHistory } from "vue-router";
import Body from "../components/body";

/* Dashboards */
import MyDashboard from "@/pages/dashboard/index.vue";
/* Authentication */
import ResetPassword from "../pages/authentication/reset_password";
//user
import activateView from "@/pages/users/activate/activateView";
import userProfile from "@/pages/users/profile/userProfile.vue";
import userEdit from "@/pages/users/edit/userEdit.vue";
// settings
import settings from "@/pages/settings/settings.vue"
// career
import ranksView from "@/pages/career/ranks/ranksView.vue";
import myCourse from "@/pages/career/my/course.vue";
// investment
import projects from "@/pages/investment/projectlist/indexList.vue";
import oneProject from "@/pages/investment/one/certificate.vue";
//network
import myNetwork from "@/pages/networks/network.vue";
//finance
import financeMy from "@/pages/finance/finance.vue";
import historyFinance from "@/pages/finance/history.vue";
// vocabulary
import vocabulary from "@/pages/vocabulary/index.vue"
/* Auth */
import login from "../auth/login";
import Register from "../auth/register";
/* Муниципалитет */
import municipal from "@/pages/municipal/municipal.vue";
/* Партнеры */
import Partners from "@/pages/itServices/partners/index.vue";
import feedbackUserService from "@/pages/itServices/userService.vue";
/* Мероприятия */
import events from "@/pages/events/events.vue";
import eventDetail from "@/pages/events/eventDetail.vue";
import eventAdd from "@/pages/events/eventAdd.vue";
import eventEdit from "@/pages/events/eventEdit.vue";
import tickets from "@/pages/events/tickets.vue";
/* Верификация */
import Verification from "@/pages/verification/index.vue";
import VerificarionList from "@/pages/verification/verificationDetail.vue";
/* IT услуги */
import feedbackService from "@/pages/itServices/feedbackService.vue";
import adminDetail from "@/pages/itServices/adminDetail.vue";
/* Новости */
import newsList from "@/pages/news/list.vue";
import newsDetail from "@/pages/news/detail.vue";
import newsManage from "@/pages/news/manage.vue";
import newsCreate from "@/pages/news/create.vue";
import newsEdit from "@/pages/news/edit.vue";
/* Проекты */
import projectsList from "@/pages/vote/list.vue";
import projectsDetail from "@/pages/vote/detail.vue";
import projectsManage from "@/pages/vote/manage.vue";
import projectsCreate from "@/pages/vote/create.vue";
import projectsEdit from "@/pages/vote/edit.vue";
/* Документы */
import internalCredit from "@/pages/documents/internalСredit.vue";
import moneyTransfer from "@/pages/documents/moneyTransfer.vue";
import moneyTransferNotification from "@/pages/documents/money-transfer-notifacation.vue";
import certificateTransfer from "@/pages/documents/certificateTransfer.vue";
/* Оплата */
import cloudPayments from "@/pages/payment/cloudpayments.vue";
/* Тесты */
import testAdminIndex from "@/pages/tests/indexAdmin.vue";
import testAdminAdd from "@/pages/tests/addAdmin.vue";
import testUserIndex from "@/pages/tests/indexUser.vue";
import testAdminEdit from "@/pages/tests/editAdmin.vue";
import testUserTest from "@/pages/tests/testUser.vue";
/* Поддержка */
import supportUser from "@/pages/support/user.vue";
import supportStaff from "@/pages/support/staff.vue";
import supportAdmin from "@/pages/support/admin.vue";
/* Ошибки */
import notFound from "@/pages/error/notFound.vue"
import serverFatalError from "@/pages/error/serverFatalError.vue"
/*Инструменты для бизнеса*/
import BusinessTool from "@/pages/BusinessTool/BusinessTool.vue";
/* Форум */
import indexUser from "@/pages/forum/indexUser.vue";
import addUser from "@/pages/forum/addUser.vue";
import chatForum from "@/pages/forum/chat.vue";
import chatAdmin from "@/pages/forum/chatAdmin.vue";
import adminList from "@/pages/forum/adminList.vue";
/* Чаты */
import chatIndex from "@/pages/chats/index.vue"
/* Учредители */
import FoundersUsers from "@/pages/founders/foundersUsers.vue"
import Founders from "@/pages/founders/founders.vue"

const routes = [
  {
    path: "/",
    component: Body,
    children: [
      {
        path: "",
        name: "defaultRoot",
        component: userProfile,
        meta: {
          title: " Рантье",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: notFound,
  },
  {
    path: "/error-fatal",
    component: serverFatalError,
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "my",
        name: "my",
        component:userProfile,
        meta: {
          title: "Мой капитал",
        },
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    children: [
      {
        path: "activate",
        name: "activate",
        component: activateView,
        meta: {
          title: "Активация аккаунта",
        },
      },
      {
        path: "profile",
        name: "profile",
        component: userProfile,
        meta: {
          title: "Профиль",
        },
      },
      {
        path: "edit",
        name: "edit",
        component: userEdit,
        meta: {
          title: "Редактировать профиль",
        },
      },
      {
        path: "settings",
        name: "profile",
        component: settings,
        meta: {
          title: "Настройки",
        },
      },
    ],
  },
  {
    path: "/payments",
    component: Body,
    children: [
      {
        path: "cloudPayments",
        name: "cloudPayments",
        component: cloudPayments,
        meta: {
          title: "Оплата",
        },
      },
    ],
  },
  {
    path: "/business",
    component: Body,
    children: [
      {
        path: "tools",
        name: "businessTools",
        component: BusinessTool,
      }
    ]
  },
  {
    path: "/career",
    component: Body,
    children: [
      {
        path: "courses",
        name: "courses",
        component: ranksView,
        meta: {
          title: "Ранги",
        },
      },
      {
        path: "my",
        name: "myCourse",
        component: myCourse,
        meta: {
          title: "Ранги",
        },
      },
    ],
  },

  {
    path: "/career",
    component: Body,
    children: [
      {
        path: "ranks",
        name: "ranks",
        component: ranksView,
        meta: {
          title: "Ранги",
        },
      },
      {
        path: "my",
        name: "myCourse",
        component: myCourse,
        meta: {
          title: "Ранги",
        },
      },
    ],
  },
  {
    path: "/investment",
    component: Body,
    children: [
      {
        path: "projects",
        name: "projects",
        component: projects,
        meta: {
          title: "Список проектов",
        },
      },
      {
        path: "one",
        name: "one",
        component: oneProject,
        meta: {
          title: "Сертификаты",
        },
      },
    ],
  },
  {
    path: "/chat",
    component: Body,
    children: [
      {
        path: "index",
        name: "indexChat",
        component: chatIndex,
        meta: {
          title: "Чаты",
        },
      },
      {
        path: ":chatId",
        name: "chatId",
        component: chatIndex,
        meta: {
          title: "Чаты",
        },
      },
    ],
  },
  {
    path: "/vote",
    component: Body,
    children: [
      {
        path: "",
        name: "projectsList",
        component: projectsList,
        meta: {
          title: "Проекты",
        },
      },
      {
        path: "create",
        name: "projectsCreate",
        component: projectsCreate,
        meta: {
          title: "Создать проект",
        },
      },
      {
        path: ":id",
        name: "projectsDetail",
        component: projectsDetail,
        meta: {
          title: "Проект",
        },
      },
      {
        path: "edit/:id",
        name: "projectsEdit",
        component: projectsEdit,
        meta: {
          title: "Редактировать проект",
        },
      },
      {
        path: "manage",
        name: "projectsManage",
        component: projectsManage,
        meta: {
          title: "Управление проектами",
        },
      },
    ],
  },
  {
    path: "/networks",
    component: Body,
    children: [
      {
        path: "my",
        name: "myNetwork",
        component: myNetwork,
        meta: {
          title: "Моя сеть",
        },
      },
    ],
  },
  {
    path: "/forum",
    component: Body,
    children: [
      {
        path: "user/list",
        name: "forumUser",
        component: indexUser,
        meta: {
          title: "Форум",
        },
      },
      {
        path: "admin/list",
        name: "forumAdmin",
        component: adminList,
        meta: {
          title: "Форум",
        },
      },
      {
        path: "user/list/add",
        name: "addForumUser",
        component: addUser,
        meta: {
          title: "Форум",
        },
      },
      {
        path: "chat/:id",
        name: "chatForum",
        component: chatForum,
        meta: {
          title: "Форум",
        },
      },
      {
        path: "chat/admin/:id",
        name: "chatAdminForum",
        component: chatAdmin,
        meta: {
          title: "Форум",
        },
      },
    ],
  },
  {
    path: "/finance",
    component: Body,
    children: [
      {
        path: "my",
        name: "myFinance",
        component: financeMy,
        meta: {
          title: "Мои финансы",
        },
      },
      {
        path: "history",
        name: "historyFinance",
        component: historyFinance,
        meta: {
          title: "История",
        },
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: login,
        meta: {
          title: "Вход",
        },
      },
      {
        path: "register",
        name: "register",
        component: Register,
        meta: {
          title: "Регистрация",
        },
      },
    ],
  },

  {
    path: "/founders",
    component: Body,
    children: [
      {
        path: "users",
        component: FoundersUsers,
      },
      {
        path: "list",
        component: Founders,
      },
    ],
  },

  {
    path: "/itService",
    component: Body,
    children: [
      {
        path: "more/:id",
        component: adminDetail,
      },
      {
        path: "feedback",
        name: "change",
        component: feedbackService,
        meta: {
          title: "Мои финансы",
        },
      },
    ],
  },
  {
    path: "/municapal",
    component: Body,
    children: [
      {
        path: "index",
        name: "Municipal",
        component: municipal,
        meta: {
          title: "Муниципалитет",
        },
      },
      {
        path: "feedback",
        name: "Offer",
        component: feedbackUserService,
        meta: {
          title: "Услуги",
        },
      },
      {
        path: "partners",
        name: "partners",
        component: Partners,
        meta: {
          title: "Партнеры",
        },
      },
    ],
  },
  {
    path: "/tests",
    component: Body,
    children: [
      {
        path: "admin/list",
        name: "test",
        component: testAdminIndex,
        meta: {
          title: "Тесты",
        },
      },
      {
        path: "admin/add",
        name: "addTest",
        component: testAdminAdd,
        meta: {
          title: "Добавление теста",
        },
      },
      {
        path: "user/list/:id",
        name: "PresentPassingTest",
        component: testUserIndex,
        meta: {
          title: "Тестирование",
        },
      },
      {
        path: "user/test/:id",
        name: "passingTest",
        component: testUserTest,
        meta: {
          title: "Тестирование",
        },
      },
      {
        path: "admin/edit/:id",
        name: "editTest",
        component: testAdminEdit,
        meta: {
          title: "Редактирование",
        },
      },
    ],
  },
  {
    path: "/events",
    component: Body,
    children: [
      {
        path: "list",
        name: "events",
        component: events,
        meta: {
          title: "Мероприятия",
        },
      },
      {
        path: "tickets",
        name: "tickets",
        component: tickets,
        meta: {
          title: "Билеты",
        },
      },
      {
        path: "list/:id",
        component: eventDetail,
        meta: {
          title: "Мероприятие",
        },
      },
      {
        path: "list/create",
        component: eventAdd,
        meta: {
          title: "Создание мероприятия",
        },
      },
      {
        path: "edit/:id",
        component: eventEdit,
        meta: {
          title: "Редактирование мероприятия",
        },
      },
    ],
  },
  {
    path: "/news",
    component: Body,
    children: [
      {
        path: "",
        name: "news",
        component: newsList,
        meta: {
          title: "Новости",
        },
      },
      {
        path: "create",
        name: "newsCreate",
        component: newsCreate,
        meta: {
          title: "Создать новость",
        },
      },
      {
        path: ":id",
        name: "newsDetail",
        component: newsDetail,
        meta: {
          title: "Новость",
        },
      },
      {
        path: "edit",
        name: "newsEdit",
        component: newsEdit,
        meta: {
          title: "Редактировать новость",
        },
      },
      {
        path: "manage",
        name: "newsManage",
        component: newsManage,
        meta: {
          title: "Управление новостями",
        },
      },
    ],
  },
  {
    path: "/auth/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Забыли пароль",
    },
  },
  {
    path: "/verification",
    component: Body,
    children: [
      {
        path: "my",
        name: "verification",
        component: Verification,
        meta: {
          title: "Верификация",
        },
      },
      {
        path: "my/list/:id",
        name: "Passport",
        component: VerificarionList,
        meta: {
          title: "Паспорт",
        },
      },
    ],
  },
  {
    path: "/vocabulary",
    component: Body,
    children: [
      {
        path: "information",
        name: "information",
        component: vocabulary,
        meta: {
          title: "СЛОВАРЬ «РАНТЬЕ+»",
        },
      },
    ],
  },
  {
    path: "/documents",
    component: Body,
    children: [
      {
        path: "internal-credit",
        name: "internalCredit",
        component: internalCredit,
        meta: {
          title: "Заявление о зачёте",
        },
      },
      {
        path: "money-transfer",
        name: "moneyTransfer",
        component: moneyTransfer,
        meta: {
          title: "Договор уступки",
        },
      },
      {
        path: "money-transfer-notification",
        name: "moneyTransferNotification",
        component: moneyTransferNotification,
        meta: {
          title: "Уведомление",
        },
      },
      {
        path: "certificate-transfer",
        name: "certificate-transfer",
        component: certificateTransfer,
        meta: {
          title: "Передача сертификата",
        },
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    name: "support",
    meta: {
      title: "Поддержка",
    },
    children: [
      {
        path: "",
        name: "userSupport",
        component: supportUser,
        meta: {
          title: "Поддержка",
        },
      },
      {
        path: "staff",
        name: "staffSupport",
        component: supportStaff,
        meta: {
          title: "Поддержка",
        },
      },
      {
        path: "admin",
        name: "adminSupport",
        component: supportAdmin,
        meta: {
          title: "Поддержка",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/my/"),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title + " | Рантье";
  const path = ["/auth/login", "/auth/register", "/auth/resetpassword"];
  if (path.includes(to.path) || localStorage.getItem("User")) {
    return next();
  }
  next(`/auth/login?next=${to.path}`);
});
export default router;
