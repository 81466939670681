<template>
  <div class="wrapper-chat">
    <Breadcrumbs title="ЧАТЫ" />

    <div class="modal" v-if="isOpenModalBlock" @click.self="openModalBlock()">
      <div class="modal-overlay" @click="openModalBlock()"></div>
      <div class="modal-content">
        <div class="modal-header-block">
          <h3 class="pt-3">Заблокировать</h3>
        </div>
        <div class="modal-body">
          <p>Вы уверены, что хотите заблокировать пользователя {{ respondingUser.profile.fio }}?</p>
        </div>
        <div class="modal-footer">
          <div class="cancel-button" @click="openModalBlock()">Отмена</div>
          <div class="confirm-button" @click="blockUser()" v-if="this.actualChat.blockedInfo.respondingUserBlockedUser === false">Заблокировать</div>
          <div class="confirm-button" @click="unBlockUser()" v-else-if="this.actualChat.blockedInfo.respondingUserBlockedUser === true">Разблокировать</div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="message-error-success">
        <alert-error v-if="showErrorWindow" :text="errorMessage" />
        <alert-success v-if="isSuccessMessage" :text="successMessage"/>
      </div>
    </transition>
    <div v-if="showModal" class="modalFile">
      <div class="modal-contentFile">
        <span class="closeFile" @click="closeModalFile">&times;</span>
        <div class="modal-contentFile-title">ПРИКРЕПИТЬ ФАЙЛЫ</div>
        <ul>
          <li v-for="file in selectedFiles" :key="file.name" class="file-itemFile mt-3">
            <div class="file-previewFile" v-if="file.preview">
              <img :src="file.preview" alt="Image Preview" class="file-imageFile" />
              <div class="file-name-and-size d-flex">
                <span>{{ file.name }}</span>
                <span class="file-sizeFile">{{ formatFileSize(file.size) }}</span>
              </div>
            </div>
            <div class="file-previewFile" v-else>
              <img src="../../assets/images/network/file.svg" alt="Image Preview" class="file-imageFile" />
              <div class="file-name-and-size d-flex">
                <span>{{ file.name }}</span>
                <span class="file-sizeFile">{{ formatFileSize(file.size) }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="file-warning mt-2">
          Не более 10 файлов
        </div>
        <div class="chat-send-msg d-flex" v-if="isCloseChat === false && this.actualChat && this.actualChat.blockedInfo.respondingUserBlockedUser === false" >
          <input type="text" class="chat-send-msg-input" placeholder="Напишите сообщение..." v-model="message">
          <div class="skoba-input">
            <img
                src="../../assets/images/chats/Skoba.svg"
                alt="Attach"
                @click="openFileDialog"
                style="cursor: pointer;"
            />
            <input
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                style="display: none;"
                multiple
            />
          </div>
          <div class="chat-send-btn" @click="sendMessage()">
            <img src="../../assets/images/chats/ArrowSendMessage.svg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="isOpenModalDeleteMsg" @click.self="closeModalDeleteMessage()">
      <div class="modal-overlay" @click="closeModalDeleteMessage()"></div>
      <div class="modal-content">
        <div class="modal-header-block">
          <h3 class="pt-3">Удалить сообщение</h3>
        </div>
        <div class="modal-body">
          <p>Вы уверены, что хотите удалить сообщение?</p>
          <label class="custom-checkbox custom-checkbox-delete d-flex">
            <input type="checkbox" v-model="isCheckedDelete" >
            <span class="checkmark"></span>
            <span> Также удалить у {{ respondingUser.profile.fio }} </span>
          </label>
        </div>
        <div class="modal-footer">
          <div class="cancel-button" @click="closeModalDeleteMessage()">Отмена</div>
          <div class="confirm-button" @click="deleteMsg()">Удалить сообщение</div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="isOpenModalCloseChat" @click.self="openModalCloseChat()">
      <div class="modal-overlay" @click="openModalCloseChat()"></div>
      <div class="modal-content">
        <div class="modal-header-block">
          <h3 class="pt-3">Завершить чат</h3>
        </div>
        <div class="modal-body">
          <p>Вы уверены что хотите завершить чат с {{respondingUser.profile.fio}}?</p>
        </div>
        <div class="modal-footer">
          <div class="cancel-button" @click="openModalCloseChat()">Отмена</div>
          <div class="confirm-button" @click="CloseChat()">Завершить</div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="isOpenModalDelete" @click.self="openModalDelete()">
      <div class="modal-overlay" @click="openModalDelete()"></div>
      <div class="modal-content">
        <div class="modal-header-block">
          <h3 class="pt-3">Удалить диалог</h3>
        </div>
        <div class="modal-body">
          <p>Вы уверены что хотите удалить диалог с {{respondingUser.profile.fio}}?</p>
        </div>
        <div class="modal-footer">
          <div class="cancel-button" @click="openModalDelete()">Отмена</div>
          <div class="confirm-button" @click="deleteChat()">Удалить диалог</div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="isOpenModalReport" @click.self="openModalReport()">
      <div class="modal-overlay" @click="openModalReport()"></div>
      <div class="modal-content">
        <div class="modal-header-block">
          <h3 class="pt-3">Пожаловаться</h3>
        </div>
        <div class="modal-body">
          <p>Что вам кажется недопустимым со стороны пользователя {{respondingUser.profile.fio}}?</p>
        </div>
        <div class="modal-list">
          <div class="modal-list-item d-flex" v-for="item in listReports" :key="item.id">
            <label class="custom-checkbox">
              <input
                  type="checkbox"
                  :checked="selectedId === item.id"
                  @change="handleCheckboxChange(item.id)"
              >
              <span class="checkmark"></span>
            </label>
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="cancel-button" @click="openModalReport()">Отмена</div>
          <div class="confirm-button" @click="postReport()">Отправить жалобу</div>
        </div>
      </div>
    </div>

    <div class="card card-all">
      <div class="chat-title d-flex justify-content-between">
        <div class="title-left">
          <img src="../../assets/images/network/arrowLast.svg" alt="" @click="exitChat()" v-if="chatId > 0">
          Чаты
        </div>
        <div class="title-right">
<!--          {{this.chat[0].countUnreadMessages}} новых сообщений-->
        </div>
      </div>
      <div class="chat-wrapper d-flex">
        <div :class="isOpenChat ? 'chat-list mobile' : 'chat-list'">
          <div class="chat-list-search">
            <input type="text" class="styled-input" placeholder="Введите имя">
          </div>
          <div :class="isActiveChat === item.id ? 'chat-list-main d-flex active-chat' : 'chat-list-main d-flex '" v-for="item in chats" :key="item.id" @click="updateActiveChat(item.id)">
            <div  class="avatar-circle">
              {{ getFirstLetter(item.respondingUser.profile.fio) }}
            </div>
            <div class="chat-list-inner">
              <div class="chat-msg-title-fio">
                {{item.respondingUser.profile.fio}}
              </div>
              <div class="chat-list-msg d-flex justify-content-between" v-if="item.chatMessages[0]">
                <div class="chat-list-msg-text">
                  {{ item.chatMessages[0].message }}
                </div>
                <countUnreadMessages :countUnreadMessages="item.uncheckedMessagesCount" class="countUnreadMessages"/>
              </div>
              <div class="chat-list-msg d-flex justify-content-between" v-else>
                нет сообщений
                <countUnreadMessages :countUnreadMessages="item.uncheckedMessagesCount"/>
              </div>
            </div>
          </div>
        </div>
        <div :class="isOpenChat ? 'chat-msg d-flex chat-msg-open' : 'chat-msg d-flex'" v-if="isChatId && this.isCloseChatMobile === false">
          <div class="maain-chats" >
            <div class="chat-msg-title d-flex justify-content-between">
              <div class="chat-msg-title-left d-flex align-items-center">
                <div  class="avatar-circle">
                  {{ getFirstLetter(this.respondingUser.profile.fio) }}
                </div>
                <div class="chat-msg-title-fio d-flex">
                  {{this.respondingUser.profile.fio}}
                </div>
              </div>
              <div class="chat-msg-title-right" v-if="this.respondingUser.role.id !== 9" @click="openModalBlockAndDel()" >
                <div class="dot-container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
                <div class="modal-del-and-block card"
                     :class="{'open': isOpenModalBlockAndDel, 'openModalBlock': isOpenModalBlockAndDel}"
                     v-if="isOpenModalBlockAndDel">
                  <div class="modal-del modal-unBlock d-flex" @click="openModalBlock()" v-if="this.actualChat.blockedInfo.respondingUserBlockedUser === true && this.actualUser.user.role.id !== 9">
                    <img src="../../assets/images/chats/Stop_Sign.svg" alt="">
                    Разблокировать
                  </div>
                  <div class="modal-del" @click="openModalBlock()" v-else-if="this.actualUser.user.role.id !== 9">
                    <img src="../../assets/images/chats/Stop_Sign.svg" alt="">
                    Заблокировать
                  </div>
                  <div class="modal-report pt-2" @click="openModalReport()" v-if="this.actualUser.user.role.id !== 9">
                    <img src="../../assets/images/chats/Circle_Warning.svg" alt="">
                    Пожаловаться
                  </div>
                  <div class="modal-block pt-2" @click="openModalDelete()" v-if="this.actualUser.user.role.id !== 9">
                    <img src="../../assets/images/chats/Trash_Full.svg" alt="" >
                    Удалить диалог
                  </div>
                  <div class="modal-del pt-2" @click="openModalCloseChat()" v-if="this.actualUser.user.role.id === 9">
                    <img src="../../assets/images/chats/CloseChat.svg" alt="" >
                    Завершить чат
                  </div>
                </div>
              </div>
            </div>
            <div class="wrapper-chat-msg_main" :style="{ maxHeight: computedMaxHeight }" ref="chatContainer" v-if="actualChat !== null && actualUser !== null && isCloseChatMobile === false" >
               <div class="wrapper-chat-msg" v-for="item in actualChat.chatMessages">

                 <div class="msg-wrapper d-flex" v-if="this.actualUser.user.id !== item.user.id" :ref="`item-${item.id}`" data-item-id="item.id">
                   <div class="avatar-circle">
                     {{ getFirstLetter(this.chat[0].fio) }}
                   </div>
                   <div class="card-msg" @contextmenu.prevent="openModalMSG($event, item)">
                     <div class="msg-reply-msg-body-resp" v-if="item.replyTo && item.replyTo.user">
                       <div class="msg-reply-msg-title">
                         <div class="msg-reply-msg-title-name-resp">
                           {{ item.replyTo.user.profile.firstName }} {{ item.replyTo.user.profile.lastName }}
                         </div>
                         <div class="msg-reply-msg-title-time">
                           {{ formatDate(item.replyTo.created_at) }}
                         </div>
                       </div>
                       <div class="msg-reply-msg-description mt-2">
                         {{ item.replyTo.message }}
                       </div>
                     </div>
                     <div class="msg-title d-flex justify-content-between mt-2">
                       <div class="msg-title-text">
                         {{ item.user.profile.firstName }} {{ item.user.profile.lastName }}
                       </div>
                       <div class="msg-title-time">
                         {{ formatDate(item.created_at) }}
                       </div>
                     </div>
                     <div class="msg-description pt-2">
                       {{item.message}}
                     </div>
                   </div>
                 </div>

                <div class="msg-wrapper-right d-flex" v-else-if="this.actualUser.user.id === item.user.id || item.messageUserId === this.actualUser.user.id">
                  <div class="card-msg-right" @contextmenu.prevent="openModalMSG($event, item)">
                    <div class="msg-reply-msg-body" v-if="item.replyTo && item.replyTo.user">
                      <div class="msg-reply-msg-title">
                        <div class="msg-reply-msg-title-name">
                          {{ item.replyTo.user.profile.firstName }} {{ item.replyTo.user.profile.lastName }}
                        </div>
                        <div class="msg-reply-msg-title-time">
                          {{ formatDate(item.replyTo.created_at) }}
                        </div>
                      </div>
                      <div class="msg-reply-msg-description mt-2">
                        {{ item.replyTo.message }}
                      </div>
                    </div>
                    <div class="msg-title d-flex justify-content-between mt-2">
                      <div class="msg-title-text-right">
                        Вы
                      </div>
                      <div class="msg-title-time-right d-flex">
                        {{ formatDate(item.created_at) }} 
                        <div class="msg-title-time-right-cheked">
                          <img src="../../assets/images/chats/ChekedFalse.svg" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="msg-description-right pt-2">
                      {{item.message}}
                    </div>
                  </div>
                  <div  class="avatar-circle">
                    {{ getFirstLetter(this.respondingUser.profile.fio) }}
                  </div>
                </div>



                <div ref="endOfMessages"></div>
              </div>
               <div class="statusChat" v-if="this.actualUser.user.role.id === 9 && actualChat.status.id === 2">
                 <div class="statusChat-inner">
                   Чат завершен
                 </div>
               </div>
              <transition name="fade">
                <div v-if="isModalVisible" class="modal-msg" :style="modalStyle">
                  <div class="modal-content-msg">
                    <div class="modal-bod-msg">

                      <div class="modal-msg-item d-flex" @click.prevent="replyToMessage(selectedMessage)">
                        <div class="modal-msg-item-img">
                          <img src="../../assets/images/chats/reply.svg" alt="">
                        </div>
                        <div class="modal-msg-item-text">
                          Ответить
                        </div>
                      </div>

                      <div class="modal-msg-item mt-2 d-flex" @click.prevent="copyMessage(selectedMessage)">
                        <div class="modal-msg-item-img">
                          <img src="../../assets/images/chats/Copy.svg" alt="">
                        </div>
                        <div class="modal-msg-item-text">
                          Копировать
                        </div>
                      </div>

                      <div class="modal-msg-item mt-2 d-flex" @click.prevent="selectMessage(selectedMessage)" v-if="this.actualUser.user.role.id !== 9">
                        <div class="modal-msg-item-img">
                          <img src="../../assets/images/chats/Select.svg" alt="">
                        </div>
                        <div class="modal-msg-item-text">
                          Выделить
                        </div>
                      </div>

                      <div class="modal-msg-item msg-item-red mt-2 d-flex" @click.prevent="deleteMessage(selectedMessage)" v-if="this.actualUser.user.role.id !== 9 && this.respondingUser.role.id !== 9">
                        <div class="modal-msg-item-img">
                          <img src="../../assets/images/chats/delete.svg" alt="">
                        </div>
                        <div class="modal-msg-item-text">
                          Удалить
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </transition>

            </div>
            <div class="wrapper-chat-no-msg_main" v-else>
              <div class="chat-no-msg-img">
                <img src="../../assets/images/chats/Note_Edit.svg" alt="" />
              </div>
              <div class="chat-no-msg-text">
                Выберите, кому хотели бы написать.
              </div>
            </div>
          </div>
          <div class="send-reply-msg-wrapper" >

            <div class="send-reply-msg d-flex" v-if="isOpenModalReplyMsg">
              <div class="reply-left d-flex">
                <div class="reply-img">
                  <img src="../../assets/images/chats/reply-img.svg" alt="">
                </div>
                <div class="reply-body">
                  <div class="reply-title d-flex">
                    <div class="reply-title-text">
                      <div class="reply-title-inner-text">
                        Ответить {{selectedMessage.user.profile.firstName}} {{selectedMessage.user.profile.lastName}}
                      </div>
                    </div>
                    <div class="reply-title-time">
                      10:26
                    </div>
                  </div>
                  <div class="reply-description">
                    {{ truncateMessageReply(selectedMessage.message) }}
                  </div>
                </div>
              </div>
              <div class="reply-img-close">
                <img src="../../assets/images/chats/reply-close.svg" alt="" @click="closeModalReplyMsg()">
              </div>
            </div>

            <div class="chat-send-msg d-flex" v-if="isCloseChat === false && this.actualChat && this.actualChat.blockedInfo.respondingUserBlockedUser === false && this.isCloseChatUser === false" >
              <input type="text" class="chat-send-msg-input" placeholder="Напишите сообщение..." v-model="message">
              <div class="skoba-input">
                <img
                    src="../../assets/images/chats/Skoba.svg"
                    alt="Attach"
                    @click="openFileDialog"
                    style="cursor: pointer;"
                />
                <input
                    type="file"
                    ref="fileInput"
                    @change="handleFileChange"
                    style="display: none;"
                    multiple

                />
              </div>
              <div class="chat-send-btn" @click="sendMessage()">
                <img src="../../assets/images/chats/ArrowSendMessage.svg" alt="">
              </div>
            </div>
            <div class="chat-block-msg" v-if="this.actualChat && this.isRespondingUser === true && this.actualChat.blockedInfo.userBlockedRespondingUser === true">
              <blockChat :typeBlock=1 />
            </div>
            <div class="chat-block-msg" v-else-if="this.actualChat && this.isRespondingUser === false && this.actualChat.blockedInfo.userBlockedRespondingUser === true">
              <blockChat :typeBlock=2 />
            </div>
            <div class="chat-block-msg" v-else-if="this.actualChat && this.isRespondingUser === false && this.actualChat.blockedInfo.respondingUserBlockedUser === true">
              <blockChat :typeBlock=3 />
            </div>
            <div class="chat-block-msg" v-else-if="this.actualChat && this.isRespondingUser === true && this.actualChat.blockedInfo.respondingUserBlockedUser === true">
              <blockChat :typeBlock=4 />
            </div>
            <div class="chat-block-msg" v-else-if="this.actualUser.user.role.id !== 9 && actualChat.status.id === 2">
              <blockChat :typeBlock=5 />
            </div>
            <div class="chat-close-msg" v-else-if="isCloseChat === true">
              <close-chat/>
            </div>
          </div>
        </div>
        <div class="chat-no-msg d-flex" v-else>
          <div class="chat-no-msg-img">
            <img src="../../assets/images/chats/Note_Edit.svg" alt="" />
          </div>
          <div class="chat-no-msg-text">
            Выберите, кому хотели бы написать.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countUnreadMessages from "@/pages/chats/components/countUnreadMessages.vue";
import ChatsDataService from "@/services/ChatsDataService";
import UserDataService from "@/services/UserDataService";
import CloseChat from "@/pages/chats/components/closeChat.vue";
import blockChat from "@/pages/chats/components/blockChat.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertError from "@/components/alert-error.vue";
import io from "socket.io-client";
export default {
  name: "chatIndex",

  components:{
    countUnreadMessages,
    CloseChat,
    blockChat,
    AlertError,
    AlertSuccess
  },

  data(){
    return{
      countUnreadMessage: 1,
      isActiveChat: 1,
      isOpenModalBlockAndDel: false,
      files: [],
      listReports: [],
      messageIds: [],
      blockedUserId: null,
      isOpenModalBlock: false,
      showErrorWindow: false,
      isOpenModalReport: false,
      isSuccessMessage: false,
      successMessage: "",
      errorMessage: "",
      isOpenModalDelete: false,
      isChatId: false,
      isOpenModalReplyMsg: false,
      isModalVisible: false,
      selectedMessage: null,
      isOpenModalDeleteMsg: false,
      respondingUser: null,
      isCloseChatMobile: false,
      observer: null,
      pageSizeChats: 25,
      selectedId: null,
      isCheckedDelete: false,
      isRespondingUser: false,
      isBlackList: false,
      blacklist: {
        violatorUserId: null,
        complaintId: null,
      },
      isCloseChat: false,
      message: "",
      isOpenChat: false,
      RespondingUser: null,
      isBlockChat: false,
      showNotification: false,
      socket: null,
      isOpenModalCloseChat: false,
      isCloseChatNoUser: false,
      isCloseChatUser: false,
      actualUser: null,
      actualChat: null,
      pageSize: 500,
      page: 0,
      selectedFiles: [],
      showModal: false,
      chat: [
        {
          id: 1,
          fio: "Захар П.",
          message: "Привет, чебурашкин",
          countUnreadMessages: 2,
        },
      ],
      chatId: null,
      chats: [],
    }
  },
  async mounted() {

     this.authToken = localStorage.getItem('authToken');

    this.socket = io(process.env.VUE_APP_BACKEND_API_BASE + "/chats", {
       extraHeaders: {
         Authorization: `Bearer ${this.authToken}`,
       },
     })
    this.socket.on('connect', () => {
       console.log(`Connected with socket id: ${this.socket.id}`);

     });

     document.addEventListener('click', this.handleClickOutside);
     this.chatId = this.$route.params.chatId;

    await this.getActualChat()
    await this.getActualUser()
    await this.updateRespondingUser()
    await this.getChats()
    await this.getListReport()
    this.scrollToBottom();
    // this.setupIntersectionObservers();
  },


  methods:{



    async postReport() {
      this.blacklist.violatorUserId = this.respondingUser.id;
      this.blacklist.complaintId = this.selectedId;
      try {
        await ChatsDataService.postChatReport(this.blacklist);
        console.log("Report sent successfully");
        this.isSuccessMessage = true;
        this.isOpenModalReport = false;
        this.successMessage = "Жалоба на пользователя успешно отправлена."
        setTimeout(() => {
          this.successMessage = ""
          this.isSuccessMessage = false;
        }, 3000);

      } catch (error) {
        if (error.response.status === 409){
          this.showErrorWindow = true
          this.errorMessage = "У вас уже есть жалоба на этого пользователя."
          setTimeout(() => {
            this.showErrorWindow = false
            this.errorMessage = ""
          }, 5000)
        }
        console.error("Error sending report:", error);
        this.showNotification = true;
      }
    },

    handleCheckboxChange(id) {
      if (this.selectedId === id) {
        this.selectedId = null;
      } else {
        this.selectedId = id;
      }
    },

    async getActualChat() {
      if (this.chatId > 0) {
        await this.socket.emit('room', { chatId: this.chatId });
        await this.getActualUser()
        await ChatsDataService.getChat(this.chatId, this.pageSize)
            .then(response => {
              this.actualChat = response.data;
              console.log("actualChat", this.actualChat);
            });
        await this.updateRespondingUser()
        this.isOpenChat = true;
        this.isActiveChat = this.chatId;

        await this.initializeChat()
        this.socket.on('room', (message) => {
          if (message.id) {
            this.actualChat.chatMessages.push(message);
            console.log("actualChat", this.actualChat);

          }
        });
        this.isChatId = true;
      } else {
        this.isChatId = false;
      }
    },

    // setupIntersectionObservers() {
    //   this.items.forEach((item) => {
    //     const target = this.$refs['item-' + item.id]; // Access the DOM element using refs
    //
    //     if (target) {
    //       // Use Intersection Observer
    //       useIntersectionObserver(
    //           target,
    //           ([{ isIntersecting }]) => {
    //             if (isIntersecting) {
    //               console.log(`Item ${item.id} is visible`); // Log when the item becomes visible
    //             }
    //           }
    //       );
    //     } else {
    //       console.error(`Element with ref item-${item.id} not found`);
    //     }
    //   });
    // },

    async getActualUser(){
      await UserDataService.get()
          .then(response => {
            this.actualUser = response.data;
            console.log("actualUser", this.actualUser);

          });
    },

    getChats(){
      ChatsDataService.getChats(this.pageSizeChats)
      .then(response => {
        this.chats=response.data;
        console.log("chats", this.chats)
      })
    },

    getListReport(){
      ChatsDataService.getListReport()
          .then(response => {
            this.listReports = response.data

          })
    },


    handleClickOutside(event) {
      const modal = this.$el.querySelector('.modal-msg');


      if (this.isModalVisible && modal && !modal.contains(event.target)) {
        this.closeModal();
      }
    },

    openModalMSG(event, item) {

      this.selectedMessage = item;
      if (this.isModalVisible === true){

        this.isModalVisible = false;
        this.isModalVisible = true;
        this.setModalPosition(event);

      } else {

        this.isModalVisible = true;
        this.setModalPosition(event);

      }

    },

    setModalPosition(event) {

      this.modalStyle = {
        top: event.clientY + 'px',
        left: event.clientX + 'px'
      };

    },

    closeModal() {
      this.isModalVisible = false;
    },

    replyToMessage(message) {
      this.isOpenModalReplyMsg = true
      console.log("Ответ на сообщение:", message);
      this.closeModal();
    },

    // forwardMessage(message) {
    //   console.log("Переслать сообщение:", message);
    //   this.closeModal();
    // },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    exitChat(){
      this.isOpenChat = false;
      this.chatId = null;
      this.isCloseChatMobile = true;
    },

    sendMessage() {
      console.log("message", this.message)
      console.log("this.chatId", this.chatId)
      console.log("socket", this.socket)


      if (this.selectedFiles) {
        const formData = new FormData();
        console.log("this.selectedFiles", this.selectedFiles)
        this.selectedFiles.forEach(file => {
          formData.append('files', file);
        });

        this.selectedFiles.forEach((file, index) => {
          formData.append(`names${index - 1 + 1}`, `${file.name}`);
        });
        console.log("FormData", formData)
        ChatsDataService.postFiles(formData)
      }

      if (this.isOpenModalReplyMsg === false){
        this.socket.emit('messages/send', { chatId: this.chatId, message: this.message });
      } else {
        this.socket.emit('messages/reply', { chatId: this.chatId, message: this.message, messageId: this.selectedMessage.id });
        this.isOpenModalReplyMsg = !this.isOpenModalReplyMsg
      }
      this.message = ""

          // .then(response => {
          //   console.log('Files uploaded successfully:', response);
          // })
          // .catch(error => {
          //   console.error('Error uploading files:', error);
          // });
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const files = event.target.files;
      this.selectedFiles = [];

      Array.from(files).forEach(file => {
        this.selectedFiles.push(file);
      });

      this.showModal = true;
    },

    closeModalFile() {
      this.showModal = false;
    },

    isImage(file) {
      return file && file.type && file.type.startsWith('image/');
    },

    formatFileSize(size) {
      if (typeof size !== 'number' || isNaN(size)) return '0 Bytes';

      if (size === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },

    openModalBlockAndDel(){
      this.isOpenModalBlockAndDel = !this.isOpenModalBlockAndDel
    },

    openModalDelete(){
      this.isOpenModalDelete = !this.isOpenModalDelete
    },

    openModalReport(){
      this.isOpenModalReport = !this.isOpenModalReport
    },

    async deleteChat(){
      try {
        await ChatsDataService.delChat(this.chatId)
        this.isSuccessMessage = true;
        this.isOpenModalDelete = false;
        this.successMessage = "Чат с пользователем успешно удален!"
        setTimeout(() => {
          this.successMessage = ""
          this.isSuccessMessage = false;
          window.location.reload();
        }, 3000);
      } catch (error) {
        if (error.response.status){
          this.isOpenModalDelete = false;
          this.showErrorWindow = true
          this.errorMessage = "Вы не можете удалить чат с данным пользователем"
          setTimeout(() => {
            this.showErrorWindow = false
            this.errorMessage = ""
          }, 5000)
        }
        console.error("Error sending report:", error);
        this.showNotification = true;
      }
    },

    async updateActiveChat(id) {
      this.chatId = id;
      console.log("chatIdthis.chatId", this.chatId);
      this.isOpenChat = true;
      this.isCloseChatMobile = false;
      this.isActiveChat = id;

     await this.getActualUser()
      setTimeout(() => {
        this.scrollToBottom()
      }, 30);
     await this.initializeChat()
    },

    async initializeChat() {

      if (this.chatId > 0) {
        this.scrollToBottom()
        this.authToken = localStorage.getItem('authToken');

        // this.socket = io(process.env.VUE_APP_BACKEND_API_BASE + "/chats", {
        //   extraHeaders: {
        //     Authorization: `Bearer ${this.authToken}`,
        //   },
        // });

        // this.socket.on('connect', () => {
        //   console.log(`Connected with socket id: ${this.socket.id}`);
        // });

        await this.socket.emit('room', { chatId: this.chatId });

        await ChatsDataService.getChat(this.chatId, this.pageSize)
            .then(response => {
              this.actualChat = response.data;
              console.log("actualChat", this.actualChat);
            });
        if (this.actualChat){
          await this.updateRespondingUser()
        }

        await this.socket.off('room');
        await this.socket.on('room', (message) => {
          if (message.id){
            this.actualChat.chatMessages.push(message);
            console.log("actualChat", this.actualChat)
          }

        })

        await this.socket.off('messages/send');
        await this.socket.on('messages/send', (message) => {
          if (message.id){
            this.actualChat.chatMessages.push(message);
            this.scrollToBottom();
            console.log("actualChat", this.actualChat)

            if (this.isCloseChatUser === true || this.actualChat.status.id === 2){
              this.actualChat.status.id = 1
              console.log("updateChat", this.actualChat)
              this.isCloseChatUser = false
            }
          }
        })

        await this.socket.off('messages/reply');
        await this.socket.on('messages/reply', (message) => {
          if (message.id){
            this.actualChat.chatMessages.push(message);
            this.scrollToBottom()
            console.log("actualChat", this.actualChat)
          }
        })

        await this.socket.off('messages/deletion');
        await this.socket.on('messages/deletion', (data) => {
          if (data.success === true && data.deleteForBoth === true) {
            const messageIdsToDelete = data.messageIds;

            this.actualChat.chatMessages = this.actualChat.chatMessages.filter(message => {
              return !messageIdsToDelete.includes(message.id);
            });

            console.log("Updated chat messages", this.actualChat.chatMessages);
          } else {
            console.error("Failed to delete messages:", data.message);
          }
        });

        await this.socket.off('blacklist/add');
        await this.socket.on('blacklist/add', (blacklist) => {
          if (blacklist.success === true){
            if (blacklist.blockedUser !== this.respondingUser.id && blacklist.success === true){
              this.successMessage = "Пользователь успешно заблокирован!"
              this.isSuccessMessage = true;
              setTimeout(() => {
                this.successMessage = ""
                this.isSuccessMessage = false;
              }, 3000);
            } else if (blacklist.blockedUser !== this.respondingUser.id && blacklist.success === false){
              this.showErrorWindow = true
              this.errorMessage = "Ошибка! Вы не можете заблокировать пользователя!"
              setTimeout(() => {
                this.showErrorWindow = false
                this.errorMessage = ""
              }, 5000)
            }
            this.actualChat.blockedInfo.respondingUserBlockedUser = !this.actualChat.blockedInfo.respondingUserBlockedUser
          }
        })

        await this.socket.off('status/completed');
        await this.socket.on('status/completed', (CloseChat) => {
          if (CloseChat.success === true && this.actualUser.user.role.id === 9){
              this.actualChat.status.id = 2
              this.successMessage = "Чат успешно завершен!"
              this.isSuccessMessage = true;
              setTimeout(() => {
                this.successMessage = ""
                this.isSuccessMessage = false;
              }, 3000);

          } else if (CloseChat.success === true && this.actualUser.user.role.id !== 9){
            this.actualChat.status.id = 2
            this.isCloseChatUser = true
            this.successMessage = "Чат был завершен Учредителем!"
            this.isSuccessMessage = true;
            setTimeout(() => {
              this.successMessage = ""
              this.isSuccessMessage = false;
            }, 3000);
          }

        })

        await this.socket.off('blacklist/delete');
        await this.socket.on('blacklist/delete', (blacklist) => {
          if (blacklist.success === true){
            if (blacklist.blockedUser === this.respondingUser.id && blacklist.success === true){
              this.successMessage = "Пользователь успешно разблокирован!"
              this.isSuccessMessage = true;
              setTimeout(() => {
                this.successMessage = ""
                this.isSuccessMessage = false;
              }, 3000);

            } else if (blacklist.blockedUser === this.respondingUser.id && blacklist.success === false){
              this.showErrorWindow = true
              this.errorMessage = "Ошибка! Вы не можете разблокировать данного пользователя!"
              setTimeout(() => {
                this.showErrorWindow = false
                this.errorMessage = ""
              }, 5000)
            }
            this.actualChat.blockedInfo.respondingUserBlockedUser = !this.actualChat.blockedInfo.respondingUserBlockedUser
          }
        })

        this.isChatId = true;
      } else {
        this.isChatId = false;
      }
    },

    updateRespondingUser(){
      if (this.chatId > 0) {
        if (this.actualUser.user.id === this.actualChat.user.id) {
          this.respondingUser = this.actualChat.respondingUser;
          this.isRespondingUser = true;
          console.log("respondingUser", this.respondingUser)
        } else {
          this.respondingUser = this.actualChat.user;
          this.isRespondingUser = false;
          console.log("respondingUser", this.respondingUser)
        }
        if (this.actualUser.user.role.id !== 9 && this.actualChat.status.id === 2) {
          this.isCloseChatUser = true
        }
      }
    },

    openModalBlock(){
      this.isOpenModalBlock = !this.isOpenModalBlock
    },

    async CloseChat(){
      this.isOpenModalCloseChat = false
      await this.socket.emit('status/completed', { chatId: this.chatId});
    },

    openModalCloseChat(){
      this.isOpenModalCloseChat = !this.isOpenModalCloseChat
    },

    async blockUser(){
      await this.updateRespondingUser()
      this.blockedUserId = this.respondingUser.id
      await this.socket.emit('blacklist/add', { chatId: this.chatId, blockedUserId: this.blockedUserId });
      this.isOpenModalBlock = false
      this.blockedUserId = null
    },

    async unBlockUser(){
      await this.updateRespondingUser()
      this.blockedUserId = this.respondingUser.id
      await this.socket.emit('blacklist/delete', { chatId: this.chatId, blockedUserId: this.blockedUserId });
      this.isOpenModalBlock = false
      this.blockedUserId = null
    },

    closeModalDeleteMessage(){
      this.isOpenModalDeleteMsg = !this.isOpenModalDeleteMsg
      this.RespondingUser = null;
      this.messageIds = []
    },

    copyMessage(selectedMessage) {

      if (selectedMessage && selectedMessage.message) {

        const textarea = document.createElement('textarea');
        textarea.value = selectedMessage.message;


        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');


        document.body.removeChild(textarea);

        console.log("Скопировано:", selectedMessage.message);

        // this.isOpenModalDeleteMsg = !this.isOpenModalDeleteMsg

      } else {
        console.error("Нет сообщения для копирования");
      }

    },

    closeModalReplyMsg(){
      this.isOpenModalReplyMsg = false
    },

    async deleteMsg() {

      await this.socket.emit('messages/deletion', { chatId: this.chatId, messageIds: this.messageIds, deleteForBoth: this.isCheckedDelete });

      this.actualChat.chatMessages = this.actualChat.chatMessages.filter(message => {

        return !this.messageIds.includes(message.id);
      });

      this.isOpenModalDeleteMsg = !this.isOpenModalDeleteMsg;

      console.log("Updated chat messages", this.actualChat.chatMessages);
    },

    deleteMessage(selectedMessage){
      this.isModalVisible = false;
      console.log("selectedMessage", selectedMessage)
      this.RespondingUser = selectedMessage.user.profile;

      if (Array.isArray(selectedMessage)) {

        selectedMessage.forEach(message => {
          this.messageIds.push(message.id);
        });
      } else if (selectedMessage && selectedMessage.id) {

        this.messageIds.push(selectedMessage.id);
      }

      console.log("messageIds", this.messageIds);
      this.isOpenModalDeleteMsg = !this.isOpenModalDeleteMsg
    },

    getFirstLetter(fullName) {
      return fullName ? fullName.charAt(0).toUpperCase() : '';
    },

    truncateMessage(message) {

      if (message.length > 15) {
        return message.slice(0, 15) + '...';
      }
      return message;

    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours}:${minutes.toString().padStart(2, '0')}`;
    },

    truncateMessageReply(message) {

      if (message.length > 30) {
        return message.slice(0, 60) + '...';
      }
      return message;

    }

  },

  computed: {
    computedMaxHeight() {
      return this.isOpenModalReplyMsg ? '580px' : '650px';
    },
  }
}
</script>

<style scoped>

.modal-unBlock{
  gap: 8px;
}

.modal{
  z-index: 999;
}

.modal-msg-item{
  gap: 5px;
  cursor: pointer;
  color: #52526CBF;
}

.modal-msg {
  z-index: 10;
  position: fixed;
  background-color: white;
  padding: 12px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.modal-content-msg {
  background-color: white;
  border-radius: 8px;
}

.msg-item-red{
  color: #FC4438;
}
.wrapper-chat-msg_main{
  max-height: 600px;
  overflow-y: auto;
}

.wrapper-chat{
 padding-bottom: 40px;
}

.chat-no-msg{
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60%;
}

.modalFile {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-contentFile {
  background-color: #fefefe;
  border-radius: 15px;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

.modal-contentFile-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

}

.file-warning{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FC4438;
}

.closeFile {
  color: #aaa;
  float: right;
  font-size: 28px;
}
.file-name-and-size{
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.closeFile:hover,
.closeFile:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.file-itemFile {
  display: flex;
  align-items: center;
}

.file-previewFile {
  display: flex;
  align-items: center;
}

.file-imageFile {
  border-radius: 8px;
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
}

.file-sizeFile {
  font-size: smaller;
  color: gray;
}

.chat-no-msg-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(82, 82, 108, 0.75);
}

.statusChat{
  display: flex;
  justify-content: center;
}

.statusChat-inner{
  background-color: #FFEFF3;
  color: #FC4438;
  padding: 8px 64px;
  border-radius: 8px;
}

.modal-header-block{
  display: flex;
  justify-content: center;
}

.cancel-button{
  padding: 6px 24px;
  background: rgba(247, 49, 100, 1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.message-error-success{
  position:fixed;
  z-index: 10000;
  right: 0;
  top: 60px;
}

.confirm-button{
  padding: 6px 32px;
  background: rgba(115, 102, 255, 1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.modal-content {
  position: relative;
  background: white;
  border-radius: 15px;
  z-index: 1001;
  max-width: 630px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding-left:21px;
}

.modal-body{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(82, 82, 108, 0.75);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
.modal-report{
  color: rgba(82, 82, 108, 0.75);
  cursor: pointer;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.custom-checkbox input {
  display: none;
}

.checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 4px;
  position: relative;
  transition: border-color 0.3s ease;
}

.custom-checkbox input:checked + .checkmark {
  background-color: transparent;
  border-color: rgba(115, 102, 255, 1);
}

.custom-checkbox-delete{
  gap: 10px;
}

.custom-checkbox input:checked + .checkmark::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid rgba(115, 102, 255, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.skoba-input{
  display: flex;
  align-items: center;
  text-align: center;
}

.chat-send-btn{
    margin-left: 10px;
  cursor: pointer;
  z-index: 10;
}

.chat-send-btn{
  background: rgba(115, 102, 255, 1);
  border-radius: 2px;
  padding: 14px;
}

.msg-title-time-right{
  gap: 5px;
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;

}

.title-left img{
  cursor: pointer;
  display: none;
  transform: rotate(180deg);
}

.chat-msg{
  flex-direction: column;
  justify-content: space-between;
}

.msg-title-time{
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: left;
  color: rgba(82, 82, 108, 0.8);

}

.msg-title-text{
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: rgba(82, 82, 108, 0.8);

}

.reply-img-close img{
  cursor: pointer;
}

.msg-title-text-right{
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;

}

.open.openModalBlock {
  animation: slide-enter-left 0.3s ease-in-out forwards;
}

.open:not(.openModalBlock) {

  animation: slide-enter-right 0.3s ease-in-out forwards;
}

.active-chat{
  background: rgba(248, 247, 255, 1) !important;
}

.modal-del{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(82, 82, 108, 0.75);
  cursor: pointer;
}

.msg-wrapper{
  align-items: flex-end;
}
.msg-wrapper-right{
  max-width: 740px;
  align-items: flex-end;
}
.modal-block{
  color: rgba(252, 68, 56, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.chat-list-inner{
  width: 90%;
}

.chat-list-main{
  cursor: pointer;
  padding: 12px 13px;
  gap: 10px;
  border-bottom: 1px solid rgba(222, 226, 230, 1)
}

.chat-list-msg-text{
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: end;
  max-width: 230px;
}

.chat-list-msg {
  position: relative;
  width: 100%;
  max-width: 250px;
  align-items: end;
}

.chat-list-search input{
  margin-bottom: 20px;
  width: 90%;
  background-color: white;
  background-image: url('../../assets/images/chats/Search_Magnifying_Glass.svg');
  background-position: 10px 7px;
  background-repeat: no-repeat;
  padding-left: 40px;
}

.modal-del-and-block{
  position: absolute;
  top: 35px;
  right: -10px;
  box-shadow: 0px 5px 20px 0px rgba(46, 35, 94, 0.07);
  padding: 8px 12px;
  width: 180px;
}

.chat-wrapper{
  max-height: 800px;
  margin-top: 20px;
  height: 100%;
}

.card{
  max-width: 1200px;
  margin: 0 auto;
}
.chat-msg{
  width: 60%;
  margin-left: 20px;
}

.card-msg{
  min-width: 80%;
  max-width: 80%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 16px;
  padding: 15px;
  margin: 10px;
}
.card-msg-right{
  min-width: 80%;
  max-width: 80%;
  background: rgba(115, 102, 255, 1);
  box-shadow: 0px 5px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 16px;
  color: rgba(255, 255, 255, 1);
  padding: 15px;
  margin: 10px;
}

.msg-description{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(82, 82, 108, 0.75);

}

.msg-description-right{
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;

}
.avatar-circle {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7366FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.styled-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s;
}

.styled-input:focus {
  border-color: #888;
  box-shadow: 0 0 5px rgba(136, 136, 136, 0.5);
}

.chat-send-msg-input{
  align-items: center;
  border: 0px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s;
}

.chat-title{
  border-bottom: 1px solid rgba(236, 243, 250, 1);
  padding-bottom: 20px;
}

.title-left{
  gap: 5px;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.title-right{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(82, 82, 108, 0.75);
}

.chat-list{
  width: 30%;
  max-height: 700px;
  overflow-y: auto;
}

.chat-list::-webkit-scrollbar{
  width: 4px;
  height: 12px;
}

.chat-list::-webkit-scrollbar-thumb{
  background: rgba(224, 221, 255, 1);
  border-radius: 2px;
}

.reply-img{
  align-items: center;
}

.reply-body{
  width: 100%;
}

.reply-left{
  width: 100%;
  gap: 10px;
}

.msg-reply-msg-title-name-resp{
  color: #7366FF;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

.msg-reply-msg-title-name{
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;

}

.msg-reply-msg-title{
  display: flex;
  justify-content: space-between;
}

.msg-reply-msg-title-time{
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
}

.msg-reply-msg-description{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.msg-reply-msg-body-resp{
  background-color: #F1F0FF;
  border-radius: 16px;
  padding: 12px 16px;
}

.msg-reply-msg-body{
  background-color: #9288FF;
  border-radius: 16px;
  padding: 12px 16px;
}

.send-reply-msg-wrapper{
  position: relative;
}

.send-reply-msg{
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #FFFFFF;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.reply-title{
  color: #54BA4A;
  justify-content: space-between;
}

.reply-title-inner-text{
  color: #54BA4A;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

.reply-description{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #52526CCC;
}

.card-all{
  padding: 20px;
  height: 850px;
}

.chat-msg-title-left{
  gap: 10px;
}

.chat-msg-title-right {
  position: relative;
  display: flex;
  align-items: center;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.dot-container {
  cursor: pointer;
  display: flex;
  gap: 2px;
  position: absolute;
  right: 0;
  padding: 5px;
}

.countUnreadMessages{
  position: absolute;
  right: 0px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
}
@media screen and (max-width: 1340px){
  .countUnreadMessages{
    right: 30px;
  }
}
@media screen and (max-width: 850px){
  .chat-no-msg, .mobile{
    display: none !important;
  }

  .chat-list-msg{
    max-width: none;
  }

  .chat-list{
    width: 95%;
  }

  .title-left img{
    display: block;
  }

  .chat-msg{
    width: 95%;
  }
  .chat-msg-open{
    display: flex !important;
    height: 100% !important;
  }
  .chat-wrapper{
    width: 100%;

  }
  .chat-list{
    max-width: 100% !important;
  }
}

@keyframes slide-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-enter-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-to {
  opacity: 1;
  transform: scale(1);
}
</style>