import http from "../http-common";

export default {
    get() {
        return http.get('/profile/me');
    },
    
    getNearestMeeting(){
        return http.get('/profile/get-nearest-meeting')
    },

    getAccauntHistoryByMonth(){
        return http.get('/profile/accountHistory-by-month')
    },

    getProfitByMonth(){
        return http.get('/profile/profit-by-month')
    },

    getStatusAutoPlay(){
      return http.get('/profile/settings/autoPay');
    },

    getProfile(email) {
        return http.post('/profile/user', {email});
    },

    getTopProjects() {
        return http.get('/profile/projects/top');
    },

    resetPassword(data) {
        return http.post('/auth/resetpassword', data);
    },

    getOwnerRefCode(referralCode) {
        return http.get(`/auth/partner/${referralCode}`);
    },

    create(data) {
        const refCode = data.refCode;
        return http.post(`/auth/register/${refCode}`, data);
    },

    login(data) {
        return http.post('/auth/login', data);
    },

    updatePassword(data) {
        return http.put('/auth/updatePassword', data);
    },

    updateProfile(data) {
        console.log(data)
        return http.put('/profile', data);
    },

    updateProfileWithoutRussianPassport(data) {
        return http.put('/profile/withoutRussianPassport', data);
    },

    sendTicket(data) {
        return http.post('/profile/send-email', data);
    },
    getSettings(){
        return http.get('/profile/settings')
    },
    putSettingsChangePrivate(data){
        return http.put(`/profile/settings/private?privating=${data}`)
    },
    putSettingsSetAutoPay(data){
        return http.put(`/profile/settings/autoPay?autoPay=${data}`)
    },
    
    /* loading files for profile */
    
    fileUploadsCover(file) {
        return http.post("/files/upload/profile/backgroundImage", file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    
    fileUploadsAvatar(file) {
        return http.post("/files/upload/profile/image/avatar", file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
}
